//trigger account details popup
$("body").on("click", ".popupTrigger", function (event) {
    event.preventDefault();
    $(".accountPopupContainer").fadeToggle();
});

//menu child items open and close
$("body").on("click", ".has_children", function (event) {
    event.preventDefault();

    // if this has class of open
    if ($(this).hasClass("open")) {
        var href = $(this).children("a").attr("href");
        // console.log(href);
        if (href != "" || href != "#") {
            location.href = href;
            return false;
        }
    }

    var el = $(this).children("ul");

    $("ul.open")
        .not(el)
        .each(function (i, obj) {
            $(this).removeClass("open");
            $(this).slideUp();
            $(".has_children").removeClass("open");
        });

    $(this).children("ul").slideToggle();
    $(this).children("ul").toggleClass("open");
    $(this).toggleClass("open");
});

//for mobile menu specifically
$("body").on("click", ".mobile_menu_item", function (event) {
    var width = $(window).width();

    if (width < 796) {
        if (!$(this).children(".dropdownMenu").hasClass(".openDropdown")) {
            event.preventDefault();
            $(this).children(".dropdownMenu").slideToggle();
            $(this).children(".dropdownMenu").addClass(".openDropdown");
        }

        var el = $(this).children(".dropdownMenu");

        $(".dropdownMenu")
            .not(el)
            .each(function (i, obj) {
                $(this).removeClass(".openDropdown");
                $(this).slideUp();
            });
    }
});

//open mobile menu
$("body").on("click", ".mobileMenu", function () {
    $("#page").toggleClass("mobileOpen");
    $(".menu .content").toggleClass("opacity");
    // $('.menu .content').toggle();
    $(".menuContainer").toggleClass("active");
    $(".menuContainer .menu").toggleClass("show");

    $(this).toggleClass("open");
    $(this).parent().toggleClass("open");

    if ($(".menu").hasClass("show")) {
        $("body").addClass("fixed-position");
    } else {
        $("body").removeClass("fixed-position");
    }
});

//mobile menu close button
$("body").on("click", ".closeBtn", function (event) {
    event.preventDefault();
    $(this).parent().fadeOut();
});

function isElementInView(element) {
    var windowTop = $(window).scrollTop();
    var windowBottom = windowTop + $(window).height();

    var elementTop = element.offset().top;
    var elementBottom = elementTop + element.outerHeight() + 137;

    return elementTop <= windowBottom && elementBottom >= windowTop;
}

//scrollable fixed menu on mobile
$(window).bind("scroll", function () {
    var width = $(window).width();

    if (width < 992) {
        $(".adminbar").each(function () {
            if (isElementInView($(this))) {
                // Element is in view
                $(".menu").removeClass("fixed");
                $("#fixedNavAccordion").removeClass("fixed");
            } else {
                // Element is out of view
                $(".menu").addClass("fixed");
                $("#fixedNavAccordion").addClass("fixed");

                //now also remove open menu
                $(".accountPopupContainer").hide();
            }
        });
    } else {
        $(".menu").removeClass("fixed");
        $("#fixedNavAccordion").removeClass("fixed");
    }
});

// filter open and close
//open mobile menu
$("body").on("click", ".filter-icon", function (event) {
    event.preventDefault();
    $(this).toggleClass("open");

    var id = $(this).data("filter");

    $("#" + id).toggleClass("open");
});

//payment calculation
// $('body').on('focusout', 'input.inputPayment', function(event){
//     event.preventDefault();

//     var totalArray = [];

//     $('.inputPayment').each(function() {
//         //test
//         totalArray.push($(this).val);
//     });

//     console.log(totalArray);
// });

$("body").on("keyup", "input.inputPayment", function (event) {
    event.preventDefault();

    var arr = [];

    $(".inputPayment").each(function () {
        //test
        var num = Math.round($(this).val() * 100) / 100;
        arr.push(num);
    });

    const sum = arr.reduce((accumulator, value) => {
        return accumulator + value;
    }, 0);

    $(".runningTotal").html("£" + sum.toFixed(2));
});

// bootstrap accordion to droppdown
$("body").on("click", ".selectableForm .accountName", function (event) {
    event.preventDefault();
    text = $(this).text();
    $("#formType").val(text);
    $("#formAccordion .accordion-button span").text(text);
    $(".accordion-collapse").collapse("hide");
});

//admin search bar
$("body").on("click", ".selectableForm .searchBy", function (event) {
    event.preventDefault();
    text = $(this).text();
    $("#searchType").val(text);
    $("#formAccordionSearchBy .accordion-button span").text(text);
    $("#admincollapseme.accordion-collapse").collapse("hide");
});

$("body").on("click", ".triggerDate", function (event) {
    event.preventDefault();
    $(this).prev(".datepicker").focus();
});

//datepickers
$(function () {
    $(".datepicker").daterangepicker({
        singleDatePicker: true,
        showDropdowns: true,
        // startDate: moment(),
        autoUpdateInput: false,
    });

    $(".datepicker").on("apply.daterangepicker", function (ev, picker) {
        $(this).val(picker.startDate.format("DD/MM/YYYY"));
    });

    $(".datepicker").on("cancel.daterangepicker", function (ev, picker) {
        $(this).val("");
    });
});

//customRage
$(".customRange").each(function (index, value) {
    let def = $(this).attr("data-default");
    let max = $(this).attr("max");
    let thumb = $(this).next().next(".thumb")[0];
    let track = $(this).next(".track").find(".track-inner")[0];

    var ratio = 100 / max;
    var newVal = def * ratio;

    thumb.style.left = `${newVal}%`;
    thumb.style.transform = `translate(-${newVal}%, -50%)`;
    track.style.width = `${newVal}%`;

    $(this).attr("value", def);
});

$("body").on("input", ".customRange", function (event) {
    event.preventDefault();
    let max = $(this).attr("max");
    let thumb = $(this).next().next(".thumb")[0];
    let track = $(this).next(".track").find(".track-inner")[0];
    let value = event.target.value;

    var ratio = 100 / max;
    var newVal = value * ratio;

    thumb.style.left = `${newVal}%`;
    thumb.style.transform = `translate(-${newVal}%, -50%)`;
    track.style.width = `${newVal}%`;
    $(this).attr("value", value);

    let result = $(this).next().next(".thumb").find(".rangeResult");
    result.html(value);
});

// Autoplay youtube when modal opens
autoPlayYouTubeModal();

function autoPlayYouTubeModal() {
    var trigger = $(".openVideoModal");
    trigger.click(function () {
        var theModal = $(this).data("bs-target");
        var videoSRC = $(this).data("src");

        var videoSRCauto =
            videoSRC +
            "?autoplay=1&mute=0&color=white&controls=1&modestbranding=1&playsinline=1&rel=0&enablejsapi=1";
        $(theModal + " iframe").attr(
            "allow",
            "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        );
        $(theModal + " iframe").attr("src", videoSRCauto);

        $(theModal).on("hidden.bs.modal", function (e) {
            $(theModal + " iframe").attr("src", "");
        });
    });
}

//iterate through .repeaters to add a number to the title
$(".repeater").each(function (index, value) {
    let title = $(this).find(".sectionTitle").data("title");
    let iterate = $(this).find(".sectionTitle").data("iteration");
    $(this)
        .find(".sectionTitle b")
        .html(title + " " + iterate);
});

//useradmin switch between user actions and search
$("body").on("click", ".selectUser, .userAction", function (event) {
    event.preventDefault();
    if ($(this).hasClass("selectUser")) {
        $(".userActions").hide();
        $(".searchFunction").fadeIn();
    }
    if ($(this).hasClass("userAction")) {
        $(".searchFunction").hide();
        $(".userActions").fadeIn();
    }
    $(".userAdminBar").slideDown();
    $(".userAdminBar").addClass("open").slideDown();
});

$("body").on("click", ".triggerUpload", function (event) {
    event.preventDefault();
    let target = $(this).attr("data-upload");

    $("#" + target).click();
});

$(document).ready(function () {
    if (window.File && window.FileList && window.FileReader) {
        $(".fileInput").on("change", function (e) {
            var thisID = $(this).attr("id");
            var removeAll = $(this).parent().next().find(".removeAllUpload");
            removeAll.removeClass("hidden");

            var files = e.target.files,
                filesLength = files.length;
            for (var i = 0; i < filesLength; i++) {
                var f = files[i];
                var fileReader = new FileReader();
                fileReader.fileName = f.name;
                fileReader.fileType = f.type;
                fileReader.onload = function (e) {
                    var file = e.target;
                    var filetype = e.target.fileType;
                    var filename = e.target.fileName;

                    if (
                        filetype == "image/png" ||
                        filetype == "image/svg+xml" ||
                        filetype == "image/jpeg" ||
                        filetype == "image/jpg"
                    ) {
                        var src = e.target.result;
                        var objectFit = "";
                    } else if (filetype == "application/pdf") {
                        var src = "/assets/images/pdf.svg";
                        var objectFit = "contain";
                    } else {
                        var src = "/assets/images/file.svg";
                        var objectFit = "contain";
                    }

                    $(
                        '<div class="img-thumb-wrapper card">' +
                            '<img class="img-thumb ' +
                            objectFit +
                            '" src="' +
                            src +
                            '" title="' +
                            filename +
                            '"/>' +
                            '<br/><span class="remove">x</span>' +
                            '<div class="filename">' +
                            filename +
                            "</div>" +
                            "</div>"
                    ).insertAfter("#" + thisID);
                    $(".remove").click(function () {
                        // when remove check if there are any left to hide remove all btn
                        $.when(
                            $(this).parent(".img-thumb-wrapper").remove()
                        ).then(removeAll());

                        function removeAll() {
                            var parent = $("#" + thisID).parent();
                            if (
                                $(parent).find(".img-thumb-wrapper").length == 0
                            ) {
                                $(parent)
                                    .next()
                                    .find(".removeAllUpload")
                                    .addClass("hidden");
                            }
                        }
                    });
                };
                fileReader.readAsDataURL(f);
            }
        });
    } else {
        alert("Your browser doesn't support to File API");
    }
});

$("body").on("click", ".removeAllUpload", function (event) {
    event.preventDefault();
    var input = $(this).parent().prev().find(".fileInput");
    var parent = input.parent();

    $.when($(parent).children().filter(":not(.fileInput)").remove()).then(
        $(this).addClass("hidden")
    );
});

//enable all bootstrap tooltips on all pages
// var tooltipTriggerList = [].slice.call(
//     document.querySelectorAll('[data-bs-toggle="tooltip"]')
// );
// var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
//     return new bootstrap.Tooltip(tooltipTriggerEl);
// });

// Case Reply Form

$("body").on("click", ".replyFormButton", function (event) {
    event.preventDefault();
    var replyForm = document.getElementById("replyForm");
    var requestCallForm = document.getElementById("callbackForm");

    if (replyForm) {
        if (replyForm.classList.contains("hidden")) {
            replyForm.classList.remove("hidden");
            replyForm.classList.add("show");
            if (requestCallForm) {
                requestCallForm.classList.add("hidden");
                requestCallForm.classList.remove("show");
            }
        } else {
            replyForm.classList.add("hidden");
            replyForm.classList.remove("show");
        }
    }
});

$("body").on("click", ".caseReplyFileButton", function (event) {
    event.preventDefault();
    var caseReplyFileHidden = document.getElementById("caseReplyFileHidden");
    var attachmentsContainer = document.getElementById("attachmentsContainer");

    if (attachmentsContainer) {
        if (attachmentsContainer.classList.contains("hidden")) {
            attachmentsContainer.classList.remove("hidden");
            attachmentsContainer.classList.add("show");
        } else {
            attachmentsContainer.classList.add("hidden");
            attachmentsContainer.classList.remove("show");
        }
    }
});

$("body").on("click", ".attachmentBrowseButton", function (event) {
    event.preventDefault();
    var caseReplyFileHidden = document.getElementById("caseReplyFileHidden");
    if (caseReplyFileHidden) {
        caseReplyFileHidden.click();
        console.log("hidden file input clicked");
    }
});

$("body").on("click", ".requestCallFormButton", function (event) {
    event.preventDefault();
    var requestCallForm = document.getElementById("callbackForm");
    var replyForm = document.getElementById("replyForm");

    if (requestCallForm) {
        if (requestCallForm.classList.contains("hidden")) {
            requestCallForm.classList.remove("hidden");
            requestCallForm.classList.add("show");
            if (replyForm) {
                replyForm.classList.add("hidden");
                replyForm.classList.remove("show");
            }
        } else {
            requestCallForm.classList.add("hidden");
            requestCallForm.classList.remove("show");
        }
    }
});

$(document).ready(function () {
    if ($(".rating a").length > 0) {
        $(".rating a").click(function () {
            var id = $(this).attr("id");
            var radio = $('[name="options"][data-value="' + id + '"]');
            radio.prop("checked", true);
        });
    }
});

// Get all the span elements
const spans = document.querySelectorAll(".rating a");

// Add click event listener to each span
spans.forEach((span) => {
    span.addEventListener("click", function (event) {
        event.preventDefault();
        // Remove the 'selected' class from all spans

        spans.forEach((span) => {
            span.classList.remove("selected");
        });

        // Add the 'selected' class to the clicked span
        this.classList.add("selected");

        // Change the fill color of the SVG inside the clicked span
        // You can access the SVG element inside the span and change its fill color here
    });
});

//End of case reply form

//Scroll to top of page button function

var scrollToTopBtn = document.getElementById("scrollToTopBtn");

$("body").on("click", "#scrollToTopBtn", function () {
    scrollToTop();
});

function scrollToTop() {
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
}

$("body").on("click", "#searchNotes", function () {
    var searchForm = document.getElementById("searchForm");
    if (searchForm) {
        if (searchForm.classList.contains("hidden")) {
            searchForm.classList.remove("hidden");
            searchForm.classList.add("show");
        } else {
            searchForm.classList.add("hidden");
            searchForm.classList.remove("show");
        }
    }
});

$("body").on("click", ".searchForm .searchIcon", function (event) {
    event.preventDefault();
    var searchFormSubmit = document.getElementById("searchFormSubmit");
    if (searchFormSubmit) {
        searchFormSubmit.click();
    }
});
